import React from "react";
import Navbar2 from "../Navbar/navbar2";
import MissionVision from "./missionvision";

function about() {
  return (
    <section className="text-gray-600 body-font  bg-black text-white">
      <Navbar2 />
      <div className="container mx-auto ">
        <div className="flex flex-wrap items-center h-auto ">
          <img
            alt="digital Marketing"
            className="max-lg:hidden lg:w-1/2 w-1/2 lg:h-auto h-32 object-cover object-center rounded"
            src="assests/Aboutus.png"
          />
          <div className="lg:w-1/2  lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="max-sm:text-lg max-sm:pt-16 max-md: pt-16 max-sm:px-2 font-poppins font-medium text-base  tracking-widest text-pink">
              About Us
            </h2>
            <div className="flex mb-4"></div>
            <div className="flex mb-4"></div>
            <p className="max-sm:text-sm max-sm:px-2 leading-relaxed font-poppins font-normal text-sm text-whitelight ">
              Kimbu.io is not just another tech company in Nepal.We are AI
              driven tech company climbing the tech ladder from 2023. We are
              committed towards bettering tech experience of our customers by
              creating online presence, tailored software services and erp
              solutions.Located in Thadodhunga Jhamsikhel in District Lalitpur
              Nepal. We are primarily focused on
            </p>
            <div className="flex mb-4"></div>
            <div className="flex mb-4"></div>
            <div className="max-sm:text-sm max-sm:px-2 flex flex-row  items-center gap-2">
              <span>
                <img src="assests/logo.png" className="w-3 h-3" />
              </span>
              <span className="max-sm:text-sm max-sm:px-2 font-poppins font-normal text-sm text-whitelight">
                Customized Software Development
              </span>
            </div>
            <div className="max-sm:text-sm max-sm:px-2 flex flex-row  items-center gap-2 ">
              <span>
                <img src="assests/logo.png" className="w-3 h-3" />
              </span>
              <span className="max-sm:text-sm max-sm:px-2 font-poppins font-normal text-sm text-whitelight">
                Bespoke ERP Solution
              </span>
            </div>
            <div className="max-sm:text-sm max-sm:px-2 flex flex-row  items-center gap-2 ">
              <span>
                <img src="assests/logo.png" className="w-3 h-3" />
              </span>
              <span className="max-sm:text-sm max-sm:px-2 font-poppins font-normal text-sm text-whitelight">
                Tailored Web Services
              </span>
            </div>
            <div className="flex mb-4"></div>
            <div className="flex mb-4"></div>
            <div className="max-sm:mx-2 flex mb-4 gap-16">
              <span>
                <div className="gap-2">
                  <span className="max-sm:text-xl font-semibold font-JosefinSans text-5xl w-9 h-8">
                    10
                  </span>
                  <span className="max-sm:text-xl  font-poppins font-normal text-sm">
                    Years
                  </span>
                </div>
                <div className="max-sm:text-sm  font-lg font-poppins font-normal text-sm text-pink">
                  Experience
                </div>
              </span>
              <span>
                <div className="gap-2">
                  <span className="max-sm:text-xl font-semibold font-JosefinSans text-5xl w-9 h-8">
                    13
                  </span>
                  <span className="max-sm:text-xl  font-poppins font-normal text-sm">
                    K
                  </span>
                </div>
                <div className="max-sm:text-sm  font-lg font-poppins font-normal text-sm text-pink">
                  Products
                </div>
              </span>
              <span>
                <div className="gap-2">
                  <span className="max-sm:text-xl font-semibold font-JosefinSans text-5xl w-9 h-8">
                    18
                  </span>
                  <span className="max-sm:text-xl  font-poppins font-normal text-sm">
                    K
                  </span>
                </div>
                <div className="max-sm:text-sm  font-lg font-poppins font-normal text-sm text-pink">
                  Clients
                </div>
              </span>
            </div>
          </div>
          {/* Mobile view only */}
          <div className="lg:hidden max-md:py-8">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="/assests/aboutus_3.jpeg"
            />
          </div>
        </div>
      </div>
      <MissionVision />
    </section>
  );
}

export default about;
