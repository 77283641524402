import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Homecontact = () => {
  const [from_name, setName] = useState("");
  const [from_email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [for_service, setService] = useState("");
  const [message, setMessage] = useState("");
  const formData = {
    name: from_name,
    email: from_email,
    phone_number: contact,
    service: for_service,
    message: message,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_4tvv8fj",
        "template_1d9g1lf",
        formData,
        "ZjXcK0tiOt8W4dn_C"
      )
      .then(
        (response) => {
          setName("");
          setEmail("");
          setContact("");
          setService("");
          setMessage("");
          toast.success("Sent Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        (error) => {
          setName("");
          setEmail("");
          setContact("");
          setService("");
          setMessage("");
          toast.error("Failed to Sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
  };
  return (
    <section className="min-h-screen text-white bg-black dark:bg-gray-900 lg:flex items-center">
      <div className="container mx-auto flex flex-col justify-center  py-8 lg:bg-gray-100 lg:dark:bg-gray-800 lg:px-12 xl:px-32 lg:w-1/2">
        <h1 className=" max-sm:pt-16 max-md: pt-16 max-sm:px-2 max-sm:text-base    capitalize text-pink dark:text-pink lg:text-2xl py-4 font-poppins font-medium text-base">
          Contact Us
        </h1>
        <h1 className="max-sm:px-2 max-sm:text-base font-JosefinSans font-semibold text-base  capitalize dark:text-white lg:text-3xl">
          GET IN TOUCH WITH US
        </h1>

        <p className="max-sm:px-2 mt-4  text-whitelight font-poppins font-normal text-xs ">
          Connect with us effortlessly. Our contact section awaits your message.
          Reach out for seamless assistance and let's make things happen
          together.
        </p>

        <div className="max-sm:px-4 mt-6 md:mt-8">
          <div className="flex mt-4 -mx-1.5 ">
            <div className="mt-6 space-y-8 md:mt-8">
              <div className="lg:flex flex-row">
                <div className="flex justify-center  items-center -mx-2">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7272 0.0848389L18.8542 0.389339C20.2324 0.762298 21.4888 1.49009 22.498 2.50012C23.5073 3.51015 24.234 4.76712 24.6059 6.14567L24.9092 7.2715L22.6575 7.87934L22.353 6.7535C22.0873 5.76881 21.5681 4.87098 20.8471 4.14959C20.1261 3.42819 19.2286 2.90844 18.244 2.64217L17.1182 2.33651L17.7272 0.0848389ZM0.166532 0.333339H10.1765L11.9114 8.13834L9.74136 10.3083C11.0167 12.2945 12.7051 13.9825 14.6915 15.2573L16.8615 13.0885L24.6665 14.8233V24.8333H23.4999C19.0087 24.8403 14.6118 23.5452 10.8415 21.1047C8.06167 19.3056 5.6943 16.9382 3.8952 14.1583C1.45476 10.3881 0.159609 5.99118 0.166532 1.50001V0.333339ZM2.53136 2.66667C2.72764 6.30663 3.87358 9.83176 5.8552 12.8913C7.47496 15.3937 9.60613 17.5249 12.1085 19.1447C15.1681 21.1264 18.6932 22.2723 22.3332 22.4685V16.6947L17.6047 15.6447L15.0684 18.1822L14.2949 17.7423C11.358 16.0732 8.92663 13.6418 7.25753 10.705L6.8177 9.93151L9.3552 7.39517L8.3052 2.66667H2.53136ZM16.6609 4.02584L17.7879 4.33034C18.477 4.51682 19.1052 4.88071 19.6098 5.38573C20.1144 5.89074 20.4778 6.51923 20.6637 7.20851L20.967 8.33434L18.7154 8.94217L18.4109 7.81634C18.3311 7.52092 18.1752 7.25158 17.9588 7.0352C17.7425 6.81882 17.4731 6.66296 17.1777 6.58317L16.0519 6.27867L16.6609 4.02584Z"
                      fill="white"
                    />
                  </svg>
                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400 gap-3">
                    <div className="w-[99px] h-[16px] size-3 font-poppins text-xs font-normal leading-[18px] text-whitelight">
                      Call Us
                    </div>
                    <div className="w-36 py-4 font-poppins text-xs font-normal leading-[18px] text-left text-whitelight">
                      {" "}
                      +977 9845706825
                    </div>
                  </span>
                </div>

                <p className="flex items-center  -mx-2">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.2485 16.1934L15.1668 23.2634V25.6667H17.5702L24.6402 18.5851M26.4835 15.8434L24.9902 14.3501C24.9339 14.2883 24.8655 14.2387 24.7892 14.2046C24.7129 14.1705 24.6304 14.1525 24.5468 14.1517C24.3718 14.1634 24.2085 14.2217 24.0918 14.3501L22.9252 15.5167L25.3168 17.8501L26.4835 16.6834C26.7052 16.4501 26.7052 16.0767 26.4835 15.8434ZM12.8335 21.0001H4.66683V9.33341L14.0002 15.1667L23.3335 9.33341V11.6667H25.6668V7.00008C25.6668 5.71675 24.6168 4.66675 23.3335 4.66675H4.66683C3.3835 4.66675 2.3335 5.71675 2.3335 7.00008V21.0001C2.3335 22.2834 3.3835 23.3334 4.66683 23.3334H12.8335V21.0001ZM23.3335 7.00008L14.0002 12.8334L4.66683 7.00008H23.3335Z"
                      fill="white"
                    />
                  </svg>

                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400 gap-3">
                    <div className="w-[99px] h-[16px] size-3 font-poppins text-xs font-normal leading-[18px] text-whitelight">
                      Email us
                    </div>
                    <div className="w-36 py-4 font-poppins text-xs font-normal leading-[18px] text-left text-whitelight ">
                      kimbunepal2024@gmail.com
                    </div>
                  </span>
                </p>
              </div>

              <div className="lg:flex flex-row">
                <p className="flex items-center  -mx-2">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0868 16.3333C19.1802 15.5633 19.2502 14.7933 19.2502 13.9999C19.2502 13.2066 19.1802 12.4366 19.0868 11.6666H23.0302C23.2168 12.4133 23.3335 13.1949 23.3335 13.9999C23.3335 14.8049 23.2168 15.5866 23.0302 16.3333M17.0218 22.8199C17.7218 21.5249 18.2585 20.1249 18.6318 18.6666H22.0735C20.9432 20.6129 19.15 22.0872 17.0218 22.8199ZM16.7302 16.3333H11.2702C11.1535 15.5633 11.0835 14.7933 11.0835 13.9999C11.0835 13.2066 11.1535 12.4249 11.2702 11.6666H16.7302C16.8352 12.4249 16.9168 13.2066 16.9168 13.9999C16.9168 14.7933 16.8352 15.5633 16.7302 16.3333ZM14.0002 23.2866C13.0318 21.8866 12.2502 20.3349 11.7718 18.6666H16.2285C15.7502 20.3349 14.9685 21.8866 14.0002 23.2866ZM9.3335 9.33325H5.92683C7.04524 7.38146 8.83727 5.9047 10.9668 5.17992C10.2668 6.47492 9.74183 7.87492 9.3335 9.33325ZM5.92683 18.6666H9.3335C9.74183 20.1249 10.2668 21.5249 10.9668 22.8199C8.84147 22.0876 7.05183 20.6128 5.92683 18.6666ZM4.97016 16.3333C4.7835 15.5866 4.66683 14.8049 4.66683 13.9999C4.66683 13.1949 4.7835 12.4133 4.97016 11.6666H8.9135C8.82016 12.4366 8.75016 13.2066 8.75016 13.9999C8.75016 14.7933 8.82016 15.5633 8.9135 16.3333M14.0002 4.70159C14.9685 6.10159 15.7502 7.66492 16.2285 9.33325H11.7718C12.2502 7.66492 13.0318 6.10159 14.0002 4.70159ZM22.0735 9.33325H18.6318C18.2661 7.88846 17.7255 6.49371 17.0218 5.17992C19.1685 5.91492 20.9535 7.39659 22.0735 9.33325ZM14.0002 2.33325C7.5485 2.33325 2.3335 7.58325 2.3335 13.9999C2.3335 17.0941 3.56266 20.0616 5.75058 22.2495C6.83393 23.3328 8.12006 24.1922 9.53552 24.7785C10.951 25.3648 12.4681 25.6666 14.0002 25.6666C17.0944 25.6666 20.0618 24.4374 22.2497 22.2495C24.4377 20.0616 25.6668 17.0941 25.6668 13.9999C25.6668 12.4678 25.3651 10.9507 24.7788 9.53528C24.1925 8.11981 23.3331 6.83369 22.2497 5.75034C21.1664 4.66699 19.8803 3.80763 18.4648 3.22132C17.0493 2.63502 15.5323 2.33325 14.0002 2.33325Z"
                      fill="white"
                    />
                  </svg>
                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400 gap-3">
                    <div className="w-[99px] h-[16px] size-3 font-poppins text-xs font-normal leading-[18px] text-whitelight">
                      Website
                    </div>
                    <div className="w-36 py-4 font-poppins text-xs font-normal leading-[18px] text-left text-whitelight ">
                      www.kimbu.io
                    </div>
                  </span>
                </p>

                <p className="flex items-center  -mx-2">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0002 7.58331C14.7737 7.58331 15.5156 7.8906 16.0626 8.43758C16.6095 8.98457 16.9168 9.72643 16.9168 10.5C16.9168 10.883 16.8414 11.2623 16.6948 11.6161C16.5482 11.97 16.3334 12.2915 16.0626 12.5624C15.7917 12.8332 15.4702 13.0481 15.1163 13.1946C14.7625 13.3412 14.3832 13.4166 14.0002 13.4166C13.2266 13.4166 12.4847 13.1094 11.9378 12.5624C11.3908 12.0154 11.0835 11.2735 11.0835 10.5C11.0835 9.72643 11.3908 8.98457 11.9378 8.43758C12.4847 7.8906 13.2266 7.58331 14.0002 7.58331ZM14.0002 2.33331C16.1661 2.33331 18.2433 3.19373 19.7749 4.72527C21.3064 6.25682 22.1668 8.33404 22.1668 10.5C22.1668 16.625 14.0002 25.6666 14.0002 25.6666C14.0002 25.6666 5.8335 16.625 5.8335 10.5C5.8335 8.33404 6.69391 6.25682 8.22546 4.72527C9.757 3.19373 11.8342 2.33331 14.0002 2.33331ZM14.0002 4.66665C12.4531 4.66665 10.9693 5.28123 9.87537 6.37519C8.78141 7.46915 8.16683 8.95288 8.16683 10.5C8.16683 11.6666 8.16683 14 14.0002 21.8283C19.8335 14 19.8335 11.6666 19.8335 10.5C19.8335 8.95288 19.2189 7.46915 18.125 6.37519C17.031 5.28123 15.5473 4.66665 14.0002 4.66665Z"
                      fill="white"
                    />
                  </svg>
                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400 gap-3">
                    <div className="w-[99px] h-[16px] size-3 font-poppins text-xs font-normal leading-[18px] text-whitelight">
                      Address
                    </div>
                    <div className="w-36 py-4 font-poppins text-xs font-normal leading-[18px] text-left text-whitelight ">
                      {" "}
                      Thadodhunga, Lalitpur
                    </div>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto  pt-12  ">
        <div class="relative p-8 boarder-white rounded-lg shadow-lg  sm:p-12">
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <input
                required
                type="text"
                name="from_name"
                placeholder="Enter your Name"
                value={from_name}
                onChange={(e) => setName(e.target.value)}
                className="text-black border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none"
              />
            </div>
            <div className="mb-6">
              <input
                required
                type="email"
                placeholder="Enter your Email"
                name="from_email"
                value={from_email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-black border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none"
              />
            </div>
            <div className="mb-6">
              <input
                required
                type="number"
                placeholder="Enter your Phone Number"
                name="contact"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                className="text-black order-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none"
              />
            </div>
            <div className="mb-6">
              <input
                required
                type="text"
                placeholder="Services"
                name="for_service"
                value={for_service}
                onChange={(e) => setService(e.target.value)}
                className="text-black border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full rounded border py-3 px-[14px] text-base outline-none"
              />
            </div>
            <div className="mb-6">
              <textarea
                rows="6"
                placeholder="Your Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="text-black border-stroke dark:border-dark-3 dark:text-dark-6 dark:bg-dark text-body-color focus:border-primary w-full resize-none rounded border py-3 px-[14px] text-base outline-none"
              />
            </div>
            <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-contactbutton rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Homecontact;
