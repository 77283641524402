import React from "react";

export default function footer() {
  return (
    <>
      <footer className="text-white dark:bg-gray-900 bg-black ">
        <div className=" container mx-auto max-w-screen-xl p-4 py-6 lg:py-8">
          <div className=" py-10 text-center md:text-left flex">
            <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              {/* <!-- TW Elements section --> */}
              <div className=" ">
                <div className="flex justify-center md:justify-start">
                  <a href="/" className="border-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="133"
                      height="31"
                      viewBox="0 0 971.431 235.499"
                    >
                      <g
                        id="Group_6"
                        data-name="Group 6"
                        transform="translate(-493.275 -213.885)"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          transform="translate(493.275 239.58)"
                        >
                          <text
                            id="KIMBU.IO"
                            transform="translate(596.431 128.305)"
                            fill="#fff"
                            font-size="159"
                            font-family="Sora-Bold, Sora"
                            font-weight="700"
                          >
                            <tspan x="-374" y="0">
                              Kimbu.io
                            </tspan>
                          </text>
                          <text
                            id="Shaping_Future"
                            data-name="Shaping Future"
                            transform="translate(234.931 191.804)"
                            fill="#d86ef8"
                            font-size="50"
                            font-family="Poppins-Light, Poppins"
                            font-weight="300"
                            letter-spacing="0.508em"
                          >
                            <tspan x="0" y="0">
                              SHAPING FUTURE
                            </tspan>
                          </text>
                          <g
                            id="Group_10"
                            data-name="Group 10"
                            transform="translate(0 0)"
                          >
                            <g
                              id="Group_8"
                              data-name="Group 8"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_1"
                                data-name="Path 1"
                                d="M721.631,335.436a50.424,50.424,0,1,1-100.848,0,50.449,50.449,0,0,0-50.424-50.424,50.414,50.414,0,1,1,50.424-50.424,50.419,50.419,0,0,0,50.424,50.424,50.448,50.448,0,0,1,50.424,50.424Z"
                                transform="translate(-519.935 -184.163)"
                                fill="#fff"
                              />
                              <path
                                id="Path_5"
                                data-name="Path 5"
                                d="M526.928,311.909a45.536,45.536,0,1,1,32.186,13.324A45.322,45.322,0,0,1,526.928,311.909Z"
                                transform="translate(-513.606 -123.536)"
                                fill="#d86ef8"
                              />
                              <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M526.928,311.909a45.536,45.536,0,1,1,32.186,13.324A45.322,45.322,0,0,1,526.928,311.909Z"
                                transform="translate(-402.927 -234.204)"
                                fill="#d86ef8"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>

                <p className="max-sm:text-sm pt-5 leading-relaxed font-poppins font-normal text-xs text-whitelight">
                  As a cutting-edge company, we specialize in crafting bespoke
                  solutions for ERP, Ecommerce, Billing Systems, and Digital
                  Marketing. Get ready to experience our innovative approach to
                  technology.
                </p>
                <span className="inline-flex pt-6 m:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                  <a
                    className="text-gray-500"
                    href="https://www.facebook.com/Kimbu.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      fill="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </a>
                  <a
                    className="ml-3 text-gray-500"
                    href="https://twitter.com/kimbuNepal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      fill="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                  </a>
                  <a
                    className="ml-3 text-gray-500"
                    href="https://www.instagram.com/kimbu.io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <rect
                        width="20"
                        height="20"
                        x="2"
                        y="2"
                        rx="5"
                        ry="5"
                      ></rect>
                      <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                  </a>
                  <a
                    className="ml-3 text-gray-500"
                    href="https://www.linkedin.com/company/kimbu-io/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <svg
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="0"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="none"
                        d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                      ></path>
                      <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                  </a>
                </span>
              </div>
              <div className="max-md:hidden">
                <h6 className=" mb-4 flex justify-center  uppercase md:justify-start font-poppins text-xs font-bold leading-[18px] text-justify">
                  QUICK LINKS
                </h6>
                <p className=" mb-4 pt-3 font-poppins font-normal text-xs text-whitelight">
                  <a href="/">Home</a>
                </p>
                <p className="mb-4 font-poppins font-normal text-xs text-whitelight">
                  <a href="/Services">Services</a>
                </p>
                <p className="mb-4 font-poppins font-normal text-xs text-whitelight">
                  <a href="/about-us">About Us</a>
                </p>
                <p className="font-poppins font-normal text-xs text-whitelight">
                  <a href="/contact-us">Contact Us</a>
                </p>
              </div>
              {/* <!-- Support section --> */}
              <div className="max-md:hidden">
                <h6 className="mb-4 flex justify-center  uppercase md:justify-start font-poppins text-xs font-bold leading-[18px] text-justify">
                  Support
                </h6>
                <p className=" mb-4 pt-3 font-poppins font-normal text-xs text-whitelight">
                  <a href="#!">Help Center</a>
                </p>
                <p className="mb-4 font-poppins font-normal text-xs text-whitelight">
                  <a href="#!">FAQ</a>
                </p>
              </div>
              {/* <!-- Contact section --> */}
              <div className="max-md:hidden">
                <h6 className="mb-4 flex justify-center  uppercase md:justify-start font-poppins text-xs font-bold leading-[18px] text-justify">
                  Contact
                </h6>
                <p className="mb-4 pt-3 flex items-center justify-center md:justify-start font-poppins font-normal text-xs text-whitelight">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                      width="9"
                      height="12"
                      viewBox="0 0 9 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.50008 2.79163C4.88686 2.79163 5.25779 2.94527 5.53128 3.21876C5.80477 3.49225 5.95841 3.86319 5.95841 4.24996C5.95841 4.44147 5.92069 4.63111 5.84741 4.80804C5.77412 4.98497 5.6667 5.14574 5.53128 5.28116C5.39586 5.41658 5.23509 5.524 5.05816 5.59728C4.88123 5.67057 4.69159 5.70829 4.50008 5.70829C4.11331 5.70829 3.74237 5.55465 3.46888 5.28116C3.19539 5.00767 3.04175 4.63673 3.04175 4.24996C3.04175 3.86319 3.19539 3.49225 3.46888 3.21876C3.74237 2.94527 4.11331 2.79163 4.50008 2.79163ZM4.50008 0.166626C5.58305 0.166626 6.62166 0.596833 7.38743 1.36261C8.15321 2.12838 8.58341 3.16699 8.58341 4.24996C8.58341 7.31246 4.50008 11.8333 4.50008 11.8333C4.50008 11.8333 0.416748 7.31246 0.416748 4.24996C0.416748 3.16699 0.846955 2.12838 1.61273 1.36261C2.3785 0.596833 3.41711 0.166626 4.50008 0.166626ZM4.50008 1.33329C3.72653 1.33329 2.98467 1.64058 2.43769 2.18756C1.89071 2.73455 1.58341 3.47641 1.58341 4.24996C1.58341 4.83329 1.58341 5.99996 4.50008 9.91413C7.41675 5.99996 7.41675 4.83329 7.41675 4.24996C7.41675 3.47641 7.10946 2.73455 6.56248 2.18756C6.01549 1.64058 5.27363 1.33329 4.50008 1.33329Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </span>
                  Thadodhunga, Lalitpur
                </p>
                <p className="mb-4 flex items-center justify-center md:justify-start font-poppins font-normal text-xs text-whitelight">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.69 4.39111L5.77768 7.08444V8H6.76604L9.67356 5.30222M10.4316 4.25778L9.8175 3.68889C9.79437 3.66534 9.76623 3.64647 9.73486 3.63347C9.70348 3.62047 9.66955 3.61361 9.63518 3.61333C9.56321 3.61778 9.49604 3.64 9.44806 3.68889L8.96827 4.13333L9.95184 5.02222L10.4316 4.57778C10.5228 4.48889 10.5228 4.34667 10.4316 4.25778ZM4.8181 6.22222H1.45958V1.77778L5.29789 4L9.1362 1.77778V2.66667H10.0958V0.888889C10.0958 0.4 9.66397 0 9.1362 0H1.45958C0.93181 0 0.5 0.4 0.5 0.888889V6.22222C0.5 6.71111 0.93181 7.11111 1.45958 7.11111H4.8181V6.22222ZM9.1362 0.888889L5.29789 3.11111L1.45958 0.888889H9.1362Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </span>
                  kimbunepal2024@gmail.com
                </p>
                <p className="mb-4 flex items-center justify-center md:justify-start font-poppins font-normal text-xs text-whitelight">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.52601 0.893555L9.00901 1.02405C9.59969 1.18389 10.1381 1.4958 10.5707 1.92867C11.0032 2.36154 11.3147 2.90025 11.474 3.49105L11.604 3.97355L10.639 4.23406L10.5085 3.75155C10.3946 3.32954 10.1721 2.94476 9.86311 2.63559C9.55412 2.32642 9.16946 2.10367 8.74751 1.98955L8.26501 1.85855L8.52601 0.893555ZM1.00001 1.00005H5.29001L6.03351 4.34505L5.10351 5.27505C5.65008 6.12626 6.37367 6.84969 7.22501 7.39605L8.15501 6.46655L11.5 7.21005V11.5001H11C9.07522 11.503 7.19083 10.948 5.57501 9.90205C4.38364 9.13101 3.36906 8.11642 2.59801 6.92506C1.55211 5.30922 0.997045 3.42484 1.00001 1.50005V1.00005ZM2.01351 2.00005C2.09763 3.56004 2.58875 5.07081 3.43801 6.38205C4.1322 7.45451 5.04555 8.36787 6.11801 9.06205C7.42924 9.91136 8.94002 10.4025 10.5 10.4866V8.01205L8.47351 7.56205L7.38651 8.64955L7.05501 8.46106C5.79638 7.74573 4.75434 6.70369 4.03901 5.44506L3.85051 5.11355L4.93801 4.02656L4.48801 2.00005H2.01351ZM8.06901 2.58255L8.55201 2.71305C8.84735 2.79297 9.11658 2.94893 9.33284 3.16536C9.5491 3.3818 9.70483 3.65115 9.78451 3.94655L9.91451 4.42905L8.94951 4.68955L8.81901 4.20705C8.78482 4.08045 8.71802 3.96502 8.62529 3.87228C8.53255 3.77955 8.41712 3.71275 8.29051 3.67855L7.80801 3.54805L8.06901 2.58255Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </span>
                  +977 9845706825
                </p>
                <p className="flex items-center justify-center md:justify-start font-poppins font-normal text-xs text-whitelight">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.116 7.2C9.164 6.804 9.2 6.408 9.2 6C9.2 5.592 9.164 5.196 9.116 4.8H11.144C11.24 5.184 11.3 5.586 11.3 6C11.3 6.414 11.24 6.816 11.144 7.2M8.054 10.536C8.414 9.87 8.69 9.15 8.882 8.4H10.652C10.0707 9.40097 9.14847 10.1592 8.054 10.536ZM7.904 7.2H5.096C5.036 6.804 5 6.408 5 6C5 5.592 5.036 5.19 5.096 4.8H7.904C7.958 5.19 8 5.592 8 6C8 6.408 7.958 6.804 7.904 7.2ZM6.5 10.776C6.002 10.056 5.6 9.258 5.354 8.4H7.646C7.4 9.258 6.998 10.056 6.5 10.776ZM4.1 3.6H2.348C2.92318 2.59622 3.8448 1.83674 4.94 1.464C4.58 2.13 4.31 2.85 4.1 3.6ZM2.348 8.4H4.1C4.31 9.15 4.58 9.87 4.94 10.536C3.84696 10.1594 2.92657 9.40092 2.348 8.4ZM1.856 7.2C1.76 6.816 1.7 6.414 1.7 6C1.7 5.586 1.76 5.184 1.856 4.8H3.884C3.836 5.196 3.8 5.592 3.8 6C3.8 6.408 3.836 6.804 3.884 7.2M6.5 1.218C6.998 1.938 7.4 2.742 7.646 3.6H5.354C5.6 2.742 6.002 1.938 6.5 1.218ZM10.652 3.6H8.882C8.69393 2.85697 8.41587 2.13966 8.054 1.464C9.158 1.842 10.076 2.604 10.652 3.6ZM6.5 0C3.182 0 0.5 2.7 0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C2.81451 10.7998 3.47595 11.2417 4.2039 11.5433C4.93185 11.8448 5.71207 12 6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 5.21207 12.3448 4.43185 12.0433 3.7039C11.7417 2.97595 11.2998 2.31451 10.7426 1.75736C10.1855 1.20021 9.52405 0.758251 8.7961 0.456723C8.06815 0.155195 7.28793 0 6.5 0Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </span>
                  www.kimbu.io
                </p>
              </div>
            </div>
          </div>
          <hr className="my-6 border-pink sm:mx-auto dark:border-pink-700 lg:my-4" />
          <div className="flex flex-col items-center md:flex md:flex-row md:justify-between">
            <div className="mt-4 font-poppins text-xs font-normal leading-[18px] text-justify">
              © 2024 Kimbu™. All Rights Reserved.
            </div>
            <div className="mt-4">
              <span className="px-2 font-poppins text-xs font-normal leading-[18px] text-justify">
                Terms of services
              </span>
              <span className="font-poppins text-xs font-normal leading-[18px] text-justify">
                |
              </span>
              <span className="px-2 font-poppins text-xs font-normal leading-[18px] text-justify">
                Privacy policy
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
