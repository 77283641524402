import { Button } from "bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function AboutHome() {
  const navigate = useNavigate();

  return (
    <section
      style={{
        backgroundImage: `url("assests/Gradient-bg.png")`,
        width: "100%",
        backgroundColor: "black",
      }}
      className="text-gray-600 body-font overflow-hidden "
    >
      <div className="container mx-auto ">
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-1/2">
            <img
              className="hidden md:block lg:max-w-xl object-cover object-center rounded-2xl"
              alt="hero"
              src="/assests/aboutus.png"
            />
            <img
              className="block md:hidden lg:max-w-xl object-cover object-center rounded-2xl md:py-8"
              alt="hero"
              src="/assests/aboutus_3.jpeg"
            />
          </div>
          <div className="w-full lg:w-1/2 flex flex-col lg:py-6 mt-6 lg:mt-0 justify-center">
            <h1 className="text-gray-900 text-xl font-bold mb-4 text-pink">
              About Us
            </h1>
            <h1 className="text-3xl font-bold lg:text-3xl lg:font-bold mb-2 text-white">
              KNOW US LITTLE MORE
            </h1>
            <p className="text-white leading-relaxed mb-4">
              We are a tech venture specializing in Software solutions to
              empower businesses in the digital Landscape.
            </p>
            <div>
              <button
                onClick={() => navigate("/about-us")}
                className="flex items-center justify-between text-white bg-transparent border-2 border-white py-2 px-6 rounded hover:bg-[#7F4092] hover:border-2 hover:border-[#7F4092] text-lg gap-2"
              >
                Learn More
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.51083 1.16667H1.08333C0.928624 1.16667 0.78025 1.10521 0.670854 0.995812C0.561458 0.886416 0.5 0.738043 0.5 0.583333C0.5 0.428624 0.561458 0.280251 0.670854 0.170854C0.78025 0.0614583 0.928624 0 1.08333 0H6.91667C6.9929 0.000921659 7.0682 0.0167761 7.13833 0.0466666C7.28087 0.10586 7.39414 0.21913 7.45333 0.361667C7.48322 0.431796 7.49908 0.507105 7.5 0.583333V6.41667C7.5 6.57138 7.43854 6.71975 7.32915 6.82915C7.21975 6.93854 7.07138 7 6.91667 7C6.76196 7 6.61358 6.93854 6.50419 6.82915C6.39479 6.71975 6.33333 6.57138 6.33333 6.41667V1.98917L1.4975 6.83083C1.44327 6.88551 1.37875 6.92891 1.30767 6.95852C1.23658 6.98814 1.16034 7.00338 1.08333 7.00338C1.00633 7.00338 0.930081 6.98814 0.858997 6.95852C0.787912 6.92891 0.723395 6.88551 0.669167 6.83083C0.614492 6.7766 0.571095 6.71209 0.54148 6.641C0.511865 6.56992 0.496617 6.49367 0.496617 6.41667C0.496617 6.33966 0.5 7 0.5 6.3C0.529615 6.22892 0.614492 6.05673 0.669167 6.0025L5.51083 1.16667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
