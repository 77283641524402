import React from "react";
import Navbar2 from "../Navbar/navbar2";

function services() {
  return (
    <>
      <Navbar2 />
      <section className="text-gray-600 body-font overflow-hidden bg-black text-white">
        <div className="container px-5 pt-24 pb-8 mx-auto ">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            <img
              alt="ecommerce"
              className="lg:w-1/2 lg:h-auto h-64 object-cover object-center rounded"
              src="assests/ecommerce.jpeg"
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="font-poppins text-base font-medium  tracking-widest text-pink">
                ECOMMERCE AND BILLING
              </h2>
              <div className="flex mb-4"></div>
              <div className="flex mb-4"></div>
              <p className="max-sm:text-sm leading-relaxed  font-poppins font-normal text-sm text-whitelight">
                Ecommerce and billing are essential components of online retail.
                Ecommerce platforms facilitate product listings, customer
                interactions, and transactions, while billing systems handle
                payment processing, invoicing, and financial record-keeping.
                Effective integration ensures seamless shopping experiences,
                secure payments, and accurate financial management, fostering
                trust and efficiency in the digital marketplace. Together, they
                drive the success of online businesses.
              </p>
            </div>
          </div>
        </div>

        <div className="container px-5 py-8 mx-auto ">
          <div className="lg:w-4/5 mx-auto flex flex-wrap flex-row-reverse ">
            <img
              alt="digital Marketing"
              className="lg:w-1/2  lg:h-auto h-64 object-cover object-center rounded"
              src="assests/digitalMarketing.jpeg"
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="font-poppins text-base font-medium tracking-widest text-pink">
                CUSTOMIZED SOFTWARE SERVICES
              </h2>
              <div className="flex mb-4"></div>
              <div className="flex mb-4"></div>
              <p className="max-sm:text-sm leading-relaxed  font-poppins font-normal text-sm text-whitelight lg:pr-12">
                Customized software services involve developing tailored
                applications to meet specific business needs. These services
                include analyzing requirements, designing solutions, coding,
                testing, and ongoing support. By addressing unique workflows and
                challenges, customized software enhances efficiency, improves
                productivity, and provides a competitive edge. It's ideal for
                businesses seeking personalized solutions that off-the-shelf
                software cannot offer.
              </p>
            </div>
          </div>
        </div>

        <div className="container px-5 py-8 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            <img
              alt="Billing"
              className="lg:w-1/2  lg:h-auto h-64 object-cover object-center rounded"
              src="assests/Billing.png"
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="font-poppins text-base font-medium tracking-widest text-pink">
                WEB DEVELOPMENT AND DIGITAL SERVICES
              </h2>
              <div className="flex mb-4"></div>
              <div className="flex mb-4"></div>
              <p className="max-sm:text-sm leading-relaxed  font-poppins font-normal text-sm text-whitelight">
                Web development and digital services encompass creating and
                maintaining websites and online platforms, along with providing
                various internet-based services. This includes web design,
                coding, SEO, content management, e-commerce solutions, and
                digital marketing. These services aim to enhance online
                presence, improve user experience, and drive engagement,
                ultimately helping businesses to reach and connect with their
                target audiences effectively.
              </p>
            </div>
          </div>
        </div>

        <div className="container px-5 py-8 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap flex-row-reverse">
            <img
              alt="erp"
              className="lg:w-1/2  lg:h-auto h-64 object-cover object-center rounded"
              src="assests/ERP.jpeg"
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="text-sm title-font font-poppins text-gray-500 tracking-widest text-pink">
                TAILORED ERP SYSTEM
              </h2>
              <div className="flex mb-4"></div>
              <div className="flex mb-4"></div>
              <p className="max-sm:text-sm leading-relaxed  font-poppins font-normal text-sm text-whitelight lg:pr-12">
                A tailored ERP (Enterprise Resource Planning) system is a
                customized software solution designed to integrate and manage a
                company's core business processes. Unlike generic ERP systems, a
                tailored ERP is specifically adapted to meet the unique
                requirements of a business, enhancing efficiency, scalability,
                and alignment with business goals. It streamlines operations
                across departments, improves data accuracy, and supports
                informed decision-making.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default services;
