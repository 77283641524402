import React from "react";

export default function Testimonials() {
  return (
    <section className="bg-black text-white body-font ">
      <div className="container mx-auto py-24">
        <h1 className="sm:text-base text-base text-center text-pink font-poppins font-medium mb-4">
          Testimonials
        </h1>
        <div className="sm:text-3xl text-2xl font-bold  font-JosefinSans mb-12 text-center">
          WHAT PEOPLE SAY ABOUT US?
        </div>
        <div className="flex  flex-wrap  sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="flex flex-row-reverse  object-none object-right md:w-1/3  ">
            <img
              className="max-lg:hidden  h-96  object-right "
              src="assests/leftPeople.png"
            />
          </div>
          <div className="p-2 lg:w-1/3 w-full lg:h-36 h-auto">
            <div className="h-auto flex items-center border-gray-200 border p-4 rounded-lg  ">
              <img
                alt="team"
                className="w-24 h-24 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="assests/testimonial2.jpg"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 font-poppins font-medium text-base mb-1">
                  Anjal Thapa
                </h2>
                <p className="text-whitelight font-poppins font-normal text-xs ">
                  "Exceptional service! They made registering my company a
                  breeze, turning my business idea into a reality effortlessly."
                </p>
              </div>
            </div>
            <div className="h-auto flex items-center border-gray-200 border p-4 rounded-lg my-8 ">
              <img
                alt="team"
                className="w-24 h-24 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="assests/testimonial1.jpg"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 font-poppins font-medium text-base mb-1">
                  Sumnima Yaksho
                </h2>
                <p className="text-whitelight font-poppins font-normal text-xs">
                  “Highly recommend their expertise! Professional, efficient,
                  and dedicated team. They guided me through every step
                  seamlessly.”
                </p>
              </div>
            </div>
            <div className="h-auto flex items-center border-gray-200 border p-4 rounded-lg my-8 ">
              <img
                alt="team"
                className="w-24 h-24 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                src="assests/testimonial3.jpeg"
              />
              <div className="flex-grow">
                <h2 className="text-gray-900 font-poppins font-medium text-base mb-1">
                  Sampurna Giri
                </h2>
                <p className="text-whitelight font-poppins font-normal text-xs">
                  "Fantastic service! They exceeded my expectations, providing
                  personalized assistance that ensured my business setup was
                  seamless."
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 ">
            <img
              className="max-lg:hidden h-96 object-left "
              src="assests/rightPeople.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
