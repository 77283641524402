import React from "react";
import { useNavigate } from "react-router-dom";

interface MobileNavType {
  setDisplayMobile: (value: boolean) => void;
}

const MobileNavbar: React.FC<MobileNavType> = ({ setDisplayMobile }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    setDisplayMobile(false);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-[100vh] bg-black flex flex-col items-center justify-center text-white ">
      <span
        className="absolute top-8 right-0 px-16 text-lg"
        onClick={() => setDisplayMobile(false)}
      >
        X
      </span>
      <ul className="flex flex-col items-center gap-8 text-lg">
        <li>
          <button onClick={() => handleNavigation("/")}>Home</button>
        </li>
        <li>
          <button onClick={() => handleNavigation("/about-us")}>
            About Us
          </button>
        </li>
        <li>
          <button onClick={() => handleNavigation("/services")}>
            Our Services
          </button>
        </li>
        <li>
          <button onClick={() => handleNavigation("/contact-us")}>
            Contact Us
          </button>
        </li>
      </ul>
    </div>
  );
};

export default MobileNavbar;
