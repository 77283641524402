import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";

import MobileNavbar from "./mobile_nav";

const Navbar2 = ({ transparent = false }) => {
  const [displayMobile, setDisplayMobile] = useState(false);

  const navigate = useNavigate();

  return (
    <section className={`${transparent ? "transparent" : "bg-black"}`}>
      <nav
        className={`h-[10vh] ${
          transparent ? "transparent" : "bg-black"
        } py-4 text-white text-[0.65em] lg:text-sm z-20 flex items-center container mx-auto justify-between`}
      >
        <div className="block md:block lg:block">
          <a href="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="133"
              height="31"
              viewBox="0 0 971.431 235.499"
            >
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(-493.275 -213.885)"
              >
                <g
                  id="Group_1"
                  data-name="Group 1"
                  transform="translate(493.275 239.58)"
                >
                  <text
                    id="KIMBU.IO"
                    transform="translate(596.431 128.305)"
                    fill="#fff"
                    font-size="159"
                    font-family="Sora-Bold, Sora"
                    font-weight="700"
                  >
                    <tspan x="-374" y="0">
                      Kimbu.io
                    </tspan>
                  </text>
                  <text
                    id="Shaping_Future"
                    data-name="Shaping Future"
                    transform="translate(234.931 191.804)"
                    fill="#d86ef8"
                    font-size="50"
                    font-family="Poppins-Light, Poppins"
                    font-weight="300"
                    letter-spacing="0.508em"
                  >
                    <tspan x="0" y="0">
                      SHAPING FUTURE
                    </tspan>
                  </text>
                  <g
                    id="Group_10"
                    data-name="Group 10"
                    transform="translate(0 0)"
                  >
                    <g
                      id="Group_8"
                      data-name="Group 8"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M721.631,335.436a50.424,50.424,0,1,1-100.848,0,50.449,50.449,0,0,0-50.424-50.424,50.414,50.414,0,1,1,50.424-50.424,50.419,50.419,0,0,0,50.424,50.424,50.448,50.448,0,0,1,50.424,50.424Z"
                        transform="translate(-519.935 -184.163)"
                        fill="#fff"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M526.928,311.909a45.536,45.536,0,1,1,32.186,13.324A45.322,45.322,0,0,1,526.928,311.909Z"
                        transform="translate(-513.606 -123.536)"
                        fill="#d86ef8"
                      />
                      <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M526.928,311.909a45.536,45.536,0,1,1,32.186,13.324A45.322,45.322,0,0,1,526.928,311.909Z"
                        transform="translate(-402.927 -234.204)"
                        fill="#d86ef8"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div className="hidden md:ml-6 md:block">
            <button
              className="flex space-x-4 text-white"
              onClick={() => navigate("/about-us")}
            >
              About Us
            </button>
          </div>

          <div className="relative hidden md:ml-6 md:block">
            <button
              className="flex space-x-4 text-white"
              onClick={() => navigate("/services")}
            >
              Our Services
            </button>
          </div>

          <div className="hidden md:ml-6 md:block">
            <button
              className="flex space-x-4 text-white"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className="block md:hidden lg:hidden text-lg">
          <RxHamburgerMenu onClick={() => setDisplayMobile(true)} />
        </div>
        {displayMobile === true && (
          <MobileNavbar setDisplayMobile={setDisplayMobile} />
        )}
      </nav>
    </section>
  );
};

export default Navbar2;
