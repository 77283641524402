import React from "react";

function AboutService() {
  return (
    <section
      style={{
        backgroundImage: `url("assests/Gradient-bg.png")`,
        width: "100%",
        backgroundColor: "black",
      }}
      className="text-white body-font bg-black"
    >
      <div className="pt-16 mx-auto">
        <div className=" ">
          <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
            <h2 className="lg:w-1/2 lg:pb-4 w-full leading-relaxed text-gray-500 text-pink font-poppins font-medium text-base">
              OUR SERVICES
            </h2>
            <h1 className="sm:text-3xl text-2xl font-bold  font-JosefinSans mb-2">
              WHAT DO WE PROVIDE?
            </h1>
          </div>
        </div>
        <div className="lg:px-32">
          <div className="flex flex-wrap h-full">
            <div className="xl:w-1/4 md:w-1/2 p-4 ">
              <div className="bg-aboutus white p-6 rounded-lg h-full">
                <div className="flex justify-center  ">
                  <img
                    className="size-20 object-center"
                    src="/assests/svg/Ecommerce.svg"
                    alt="content"
                  />
                </div>
                <h2 className="h-16 flex  justify-center text-gray-900 font-poppins font-medium text-base mb-4">
                  Tailored ERP System
                </h2>
                <p className="  leading-relaxed text-sm font-poppins font-normal text-whitelight">
                  We have optimized ERP systems for smooth operations and
                  processes for your business needs. 
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4 ">
              <div className="bg-aboutus white p-6 rounded-lg h-full">
                <div className="flex justify-center">
                  <img
                    className="size-20"
                    src="/assests/svg/Costume.svg"
                    alt="content"
                  />
                </div>

                <h2 className="h-16 flex justify-center  text-gray-900 font-poppins font-medium text-base mb-4">
                  Customized Software Services
                </h2>
                <p className="leading-relaxed text-sm font-poppins font-normal text-whitelight">
                  We build software solutions crafted for perfection to your
                  specific needs and requirements.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div className="bg-aboutus white p-6 rounded-lg h-full">
                <div className="flex justify-center">
                  <img
                    className="size-20"
                    src="/assests/svg/www.svg"
                    alt="content"
                  />
                </div>
                <h2 className="h-16 flex justify-center  text-gray-900 font-poppins font-medium text-base mb-4">
                  Ecommerce and Billing
                </h2>
                <p className="leading-relaxed text-sm font-poppins font-normal text-whitelight">
                  We develop comprehensive scalable platforms for e-commerce
                  with automated billing systems.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4 ">
              <div className="bg-aboutus white p-6 rounded-lg h-full">
                <div className="flex  justify-center items-center">
                  <img
                    className="size-20"
                    src="/assests/svg/ERP.svg"
                    alt="content"
                  />
                </div>
                <h2 className="h-16 flex justify-center text-gray-900 font-poppins font-medium text-base mb-4">
                  Web development and digital services
                </h2>
                <p className="leading-relaxed text-sm font-poppins font-normal text-whitelight">
                  We have a team of expert developers shaping your online
                  presence, user experience and visibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutService;
