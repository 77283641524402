import React from "react";
import Marquee from "react-fast-marquee";
import "./ImageMarquee.css"; // Import your custom CSS file

const ImageMarquee = () => {
  const images = [
    "assests/svg/companieslogo/ecomp.svg",
    "assests/svg/companieslogo/Feed.svg",
    "assests/svg/companieslogo/Golden.svg",
    "assests/svg/companieslogo/linked.svg",
    "assests/svg/companieslogo/ecomp.svg",
    "assests/svg/companieslogo/Feed.svg",
    "assests/svg/companieslogo/Golden.svg",
    "assests/svg/companieslogo/linked.svg",
    "assests/svg/companieslogo/ecomp.svg",
    "assests/svg/companieslogo/Feed.svg",
    "assests/svg/companieslogo/Golden.svg",
    "assests/svg/companieslogo/linked.svg",
    "assests/svg/companieslogo/ecomp.svg",
    "assests/svg/companieslogo/Feed.svg",
    "assests/svg/companieslogo/Golden.svg",
    "assests/svg/companieslogo/linked.svg",
  ];

  return (
    <div className="container mx-auto">
      <Marquee
        speed={50}
        gradient={false}
        direction="left"
        className="marquee-container"
      >
        {images.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`marquee image ${index}`}
            className="marquee-image"
            style={{ margin: "0 32px" }}
          />
        ))}
      </Marquee>
    </div>
  );
};

export default ImageMarquee;
