import React from "react";
import { useNavigate } from "react-router-dom";
import AboutHome from "./AboutHome";
import AboutService from "./AboutService";
import Companyslider from "../Marquee/Companyslider";
import Testimonials from "./Testimonials";
import Navbar2 from "../Navbar/navbar2";
import Homecontact from "./Homecontact";

export default function Home() {
  const navigate = useNavigate();
  return (
    <section
      style={{
        backgroundImage: `
     url('assests/Gradient-bg.png')
  `,
        width: "100%",
        backgroundColor: "black",
      }}
      className="min-md:pt-16 min-h-screen"
    >
      <Navbar2 transparent={true} />
      <div className="flex container mx-auto py-16 items-center justify-between">
        <div className="flex flex-col  py-5 lg:py-5 text-white ">
          <div className="text-left flex flex-col">
            <span className="max-md:text-3xl text-4xl font-bold font-JosefinSans tracking-tight text-pink">
              EMPOWER YOUR BUSINESS {""}
            </span>
            <span className="max-md:text-3xl text-4xl font-bold JosefinSans tracking-tight">
              WITH TAILORED SOLUTIONS
            </span>
          </div>
          <p className="max-sm:text-sm max-sm:mt-3 mt-4 text-base leading-8 font-poppins font-medium">
            KIMBU.IO WILL CRAFT DIGITAL <span className="text-pink">MAGIC</span>
            , FOR YOU
          </p>
          <div className="pt-1 hidden md:block  ">
            {/* <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded flex flex-row items-center gap-2">
              Contact
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.51083 1.16667H1.08333C0.928624 1.16667 0.78025 1.10521 0.670854 0.995812C0.561458 0.886416 0.5 0.738043 0.5 0.583333C0.5 0.428624 0.561458 0.280251 0.670854 0.170854C0.78025 0.0614583 0.928624 0 1.08333 0H6.91667C6.9929 0.000921659 7.0682 0.0167761 7.13833 0.0466666C7.28087 0.10586 7.39414 0.21913 7.45333 0.361667C7.48322 0.431796 7.49908 0.507105 7.5 0.583333V6.41667C7.5 6.57138 7.43854 6.71975 7.32915 6.82915C7.21975 6.93854 7.07138 7 6.91667 7C6.76196 7 6.61358 6.93854 6.50419 6.82915C6.39479 6.71975 6.33333 6.57138 6.33333 6.41667V1.98917L1.4975 6.83083C1.44327 6.88551 1.37875 6.92891 1.30767 6.95852C1.23658 6.98814 1.16034 7.00338 1.08333 7.00338C1.00633 7.00338 0.930081 6.98814 0.858997 6.95852C0.787912 6.92891 0.723395 6.88551 0.669167 6.83083C0.614492 6.7766 0.571095 6.71209 0.54148 6.641C0.511865 6.56992 0.496617 6.49367 0.496617 6.41667C0.496617 6.33966 0.5 7 0.5 6.3C0.529615 6.22892 0.614492 6.05673 0.669167 6.0025L5.51083 1.16667Z"
                  fill="white"
                />
              </svg>
            </button> */}
            <button
              className="flex items-center text-white bg-transparent border-2 border-white py-2 px-4 rounded hover:bg-[#7F4092] hover:border-2 hover:border-[#7F4092] text-lg gap-2"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us{""}
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.51083 1.16667H1.08333C0.928624 1.16667 0.78025 1.10521 0.670854 0.995812C0.561458 0.886416 0.5 0.738043 0.5 0.583333C0.5 0.428624 0.561458 0.280251 0.670854 0.170854C0.78025 0.0614583 0.928624 0 1.08333 0H6.91667C6.9929 0.000921659 7.0682 0.0167761 7.13833 0.0466666C7.28087 0.10586 7.39414 0.21913 7.45333 0.361667C7.48322 0.431796 7.49908 0.507105 7.5 0.583333V6.41667C7.5 6.57138 7.43854 6.71975 7.32915 6.82915C7.21975 6.93854 7.07138 7 6.91667 7C6.76196 7 6.61358 6.93854 6.50419 6.82915C6.39479 6.71975 6.33333 6.57138 6.33333 6.41667V1.98917L1.4975 6.83083C1.44327 6.88551 1.37875 6.92891 1.30767 6.95852C1.23658 6.98814 1.16034 7.00338 1.08333 7.00338C1.00633 7.00338 0.930081 6.98814 0.858997 6.95852C0.787912 6.92891 0.723395 6.88551 0.669167 6.83083C0.614492 6.7766 0.571095 6.71209 0.54148 6.641C0.511865 6.56992 0.496617 6.49367 0.496617 6.41667C0.496617 6.33966 0.5 7 0.5 6.3C0.529615 6.22892 0.614492 6.05673 0.669167 6.0025L5.51083 1.16667Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="hidden lg:block">
          <img
            src="assests/herosection/herosection.png"
            style={{ width: "450px", height: "350px" }}
          />
        </div>
      </div>
      <div className="hidden lg:block  pb-6">
        <Companyslider />
      </div>

      {/* <div className="bg-black opacity-95"></div> */}
      <AboutHome />
      <AboutService />
      <Testimonials />
      <Homecontact />
    </section>
  );
}
