import React from "react";

export default function missionvision() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-6 mx-auto flex flex-wrap">
        <div className="flex flex-wrap -m-4 lg:px-36 py-5">
          <div className="p-4 lg:w-1/2 min-md:w-16">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 h-full  sm:flex-row flex-col">
              <div className="flex-grow">
                <h2 className="font-poppins text-gray-900 text-lg title-font font-medium mb-3">
                  Our Mission
                </h2>
                <p className="leading-relaxed font-poppins font-normal text-sm text-whitelight">
                  We are focused on helping other businesses thrive by providing
                  digitals services tailor made for their specific needs and
                  requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="p-4 lg:w-1/2 min-md:w-16">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 h-full sm:flex-row flex-col">
              <div className="flex-grow">
                <h2 className="font-poppins text-gray-900 text-lg title-font font-medium mb-3">
                  Our Goal
                </h2>
                <p className="leading-relaxed font-poppins font-normal text-sm text-whitelight">
                  Our goal is to end tech market dominance of old tech giants by
                  offering innovative and affordable solutions. We facilitate
                  seamless and affordable web and software services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
